<template>
  <div class="pageHomeViewByModuleCarousel">
    <div class="pageHomeViewByModuleCarousel__header">
      <CommonBoxModule :module="data">
        <template v-slot:controls>
          <CommonControlsCarousel
            :disableNext="isEnd || isLocked"
            :disablePrev="isBeginning || isLocked"
            @prev="prev(uniqueId)"
            @next="next(uniqueId)"
          />
        </template>
      </CommonBoxModule>
    </div>

    <div class="pageHomeViewByModuleCarousel__container">
      <div class="pageHomeViewByModuleCarousel__container--box">
        <Swiper :ref="`${uniqueId}`" :options="swiperOption">
          <!-- Loading -->
          <template v-if="loading">
            <SwiperSlide v-for="i in 6" :key="i">
              <CommonSkeletonsCover />
            </SwiperSlide>
          </template>

          <!-- Carousel View -->
          <template v-else>
            <SwiperSlide v-for="item in filterOnShow(data.modules)" :key="item.id">
              <div>
                  <CommonCardsModule 
                    :data="{ ...item, course: { has_access: data.has_access, is_liberated: data.is_liberated, liberated_at: data.liberated_at } }"
                    @show="checkPermission(item, data.is_liberated)"
                    :no-cover="data.cover" 
                  />
                  <CommonCardsTitle 
                    @show="checkPermission(item, data.is_liberated)"
                    :data="{ ...item, course: { has_access: data?.has_access, is_liberated: data.is_liberated, liberated_at: data.liberated_at } }"
                  />
              </div>
            </SwiperSlide>
          </template>
        </Swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  created() {
    this.uniqueId = this.createUniqueId();
  },
  computed: {
    ...mapGetters({
      getIsAdmin: "config/getIsAdmin",
    }),
  },
  components: {
    CommonBoxModule: require("@/components/common/box/Module.vue").default,
    CommonCardsModule: require("@/components/common/cards/Module.vue").default,
    CommonCardsTitle: require("@/components/common/cards/title/Title.vue").default,
    CommonSkeletonsCover: require("@/components/common/skeletons/Cover.vue").default,
    CommonControlsCarousel: require("@/components/common/carousel/ControlsCarousel.vue").default
  },
  props: {
    loading: {
      type: Boolean,
    },
    controlRef: {
      type: String,
    },
    data: {
      type: [Object],
      default: () => [{}],
    },
    poster: {
      type: Boolean,
    },
    showRating: {
      type: Boolean,
    },
  },
  methods: {
    ...mapActions({
      actionFetchCourseAvailability: "home/actionFetchCourseAvailability",
    }),
    checkPermission(item, courseIsLiberated) {
      if (item.has_access === false) {
        return false
      }

      if(item.is_liberated === false) {
        return false
      }

      if(courseIsLiberated === false) {
        return false
      }

      return this.$router.push({ path: `/curso/${item.course_id}/modulo/${item.id}` })
    },
    filterOnShow(modules) {
      return modules?.filter((item) => item?.should_show === true)
    },
    allowCourse(item) {
      return (
        this.data?.is_liberated === item ||
        (this.data?.is_liberated === item && item)
      );
    },
    async denyCourse(item) {
      const response = await this.actionFetchCourseAvailability(item?.course_id);

      const fragment = {
        type: (await response?.liberated_at) ? "module" : "course",
        allow:
          (await response?.is_liberated) === false ||
            item?.is_liberated === false
            ? false
            : true,
        date:
          item?.liberated_at !== null
            ? item.liberated_at
            : await response?.liberated_at,
      };

      const data = {
        ...fragment,
        type: fragment.type === "course" ? 1 : 4,
      };

      this.$emit("show", data);
    },
    createUniqueId() {
      const timestamp = new Date().getTime();
      const randomNumber = Math.floor(Math.random() * 10000);
      const uniqueId = `${timestamp}_${randomNumber}`;
      return uniqueId;
    },
    prev(name) {
      this.$refs[name].$swiper.slidePrev();
    },
    next(name) {
      this.$refs[name].$swiper.slideNext();
    },
  },
  data() {
    return {
      uniqueId: null,
      isLocked: false,
      isBeginning: true,
      isEnd: false,
      swiperOption: {
        direction: "horizontal",
        loop: false,
        height: 60,
        spaceBetween: 24,
        slidesPerView: "auto",
        centeredSlides: false,
        resizeReInit: true,
        watchOverflow: true,
        on: {
					lock: () => {
						this.isLocked = true;
					},
					unlock: () => {
						this.isLocked = false;
					},
					progress: progress => {
						if (progress === 1) {
							this.isBeginning = false;
							this.isEnd = true;
						} else if (progress <= 0) {
							this.isBeginning = true;
							this.isEnd = false;
						} else {
							this.isBeginning = false;
							this.isEnd = false;
						}
					},
				},
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.pageHomeViewByModuleCarousel {
  position: relative;
  margin-bottom: var(--spacing-24);

  &__container {
    position: relative;
    max-width: 100%;
    overflow: hidden;

    &--box {
      position: relative;
    }
  }

  .swiper-slide {
    position: relative;
    max-width: 153px;
    min-height: 92px;
    border-radius: var(--default-radius);
    overflow: hidden;
  }

  .swiper-wrapper {
    display: flex;
    gap: 24px;
  }
}
</style>
